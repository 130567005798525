<template>
  <div v-if="notuploaded" class="h-screen flex w-full bg-img">
    <div
      class="vx-col flex items-center justify-center flex-col sm:w-1/2 md:w-3/5 lg:w-3/4 xl:w-1/2 mx-auto text-center"
    >
      <img
        src="../assets/images/pages/graphic-1.png"
        alt="graphic-maintenance"
        class="mx-auto mb-4"
      />
      <h1 class="mb-6 text-5xl d-theme-heading-color">Excel Upload</h1>
      <p class="mb-6 d-theme-text-inverse">
        Upload an Excel file and automate your process.
      </p>
      <input type="file" id="fileUpload" />
      <vs-button
        style="margin-top: 3%"
        color="dark"
        id="upload"
        type="filled"
        @click="Upload"
        >Upload</vs-button
      >
      <vs-progress
        v-if="uploadingstatus"
        style="margin-top: 5%"
        :percent="completedpercentage"
        :color="color"
      ></vs-progress>
      <!-- <vs-row v-if="uploadingstatus" style="margin-top: 3%">
        <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="6">
          <h3>{{completedNumber}}</h3>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="6">
          <h3>{{totalNumber}}</h3>
        </vs-col>
      </vs-row>-->
    </div>
  </div>
  <div v-else>new</div>
</template>

<script src="xlsx.full.min.js"></script>
<script>
import Datepicker from "vuejs-datepicker";
import axios from "axios";
// import $ from "jquery";
import EventBus from "../components/eventbus.js";
import constants from "../../constants.json";
import router from "@/router";
import vSelect from "vue-select";
import XLSX from "xlsx";
// import ajax from "ajax";
export default {
  mounted() {
    EventBus.$emit("unloadplus", true);
    if (localStorage.getItem("role") !== "cm2") {
      if (localStorage.getItem("role") === "cm1") {
        router.push("/");
      } else if (localStorage.getItem("role") === "cm4") {
        router.push("/adminDashboard");
      }
    }
    /* this.startloading("start");
    setTimeout(() => {
      this.startloading("end");
    }, 2000); */
    // this.getSpocs();
    /* let navbar = document.getElementsByClassName("vx-navbar-wrapper");
    navbar[0].style.display = "none"; */
  },
  data() {
    return {
      completedpercentage: 0,
      file: "",
      notuploaded: true,
      uploadingstatus: true,
      color: "success",
      totalNumber: 0,
      completedNumber: 0,
      api_token:
        "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6ImJmYmYwNTcxODNjMmE4Y2EyNDI4NDQ0YzdlNDUzZTVmN2E3MTI0MWQ1MDAwMWM5ZWJmYmQ5ZDA5YWMxMzQyYTZhMTU0ZjFmN2Q2YmIwZmFlIn0.eyJhdWQiOiIxIiwianRpIjoiYmZiZjA1NzE4M2MyYThjYTI0Mjg0NDRjN2U0NTNlNWY3YTcxMjQxZDUwMDAxYzllYmZiZDlkMDlhYzEzNDJhNmExNTRmMWY3ZDZiYjBmYWUiLCJpYXQiOjE1Njc1ODc3ODUsIm5iZiI6MTU2NzU4Nzc4NSwiZXhwIjoxNTk5MjEwMTg1LCJzdWIiOiIxNjgiLCJzY29wZXMiOltdfQ.Nq_3wZSOFHz52xR8eDZVqSxUlxY_ECZvnCweGYhCWviFyQy8BjSAil7A5aL27aiQ-E_2t5f4yooN2ak0ILSmdTj3obaSc18oq38TAExu6JHXbf_Bhp21jqueI4GplH8ZXNvZenu_vH6ZHxZpnl7lnVgJyoZgiukARaiXxYGWDoYcxowjw4DQq4e_jrooY42tkpFrXv0zVRcwb3KZ7o1KzLNv4R1UuESXJKoC3ZKweuuS8NOxhyTpN7BMKzceRs6qqFnsXIiedq50TVoX1WJr6pjHxBSv9Dkr9uRc2HX1Vzt3AwunnaD-T4w3pIPj7CaMcjah7AgjVMnSqpZlPb0BcaB7hUOErIQB7yz10HHtShSSYwmTORtEkdGRef2gdW_xf7BynBHEUJyrs-wmYKVg47beksjvTz97IbbOBcD6TBi4fKmCNIbLtTMO-xtq9LGyKfCux0Yn6zFzW39m2_D_EILOOXYLqCePxOQxqi_d4ZQSSsQPw-1gifcl6VewerpXhgPtRAyFHSofY9cPPBEfxGyAxsmrYRF6Vka1pdqjYo_awitZTlHM9w7utyJunLTdsFLxDdFa8Y1cGOzN03HdTTqI0XPdqaa3HVXR8D0Nw7YRNAQ0O5VXIQiDqU79pmlnC7WPrtdKgsk3ffZaZgb1lcfLtVxuiqemhAF-mdVooR8"
    };
  },
  components: {
    Datepicker,
    "v-select": vSelect
  },
  methods: {
    ProcessExcel(data) {
      //Read the Excel File data.
      console.log("process started");
      var workbook = XLSX.read(data, {
        type: "binary"
      });

      //Fetch the name of First Sheet.
      var firstSheet = workbook.SheetNames[0];

      //Read all rows from First Sheet into an JSON array.
      var excelRows = XLSX.utils.sheet_to_row_object_array(
        workbook.Sheets[firstSheet]
      );

      //Create a HTML Table element.
      var table = document.createElement("table");
      table.border = "1";

      //Add the header row.
      var row = table.insertRow(-1);

      //Add the header cells.
      var headerCell = document.createElement("TH");
      headerCell.innerHTML = "Id";
      row.appendChild(headerCell);

      headerCell = document.createElement("TH");
      headerCell.innerHTML = "Name";
      row.appendChild(headerCell);

      headerCell = document.createElement("TH");
      headerCell.innerHTML = "Country";
      row.appendChild(headerCell);

      //Add the data rows from Excel file.
      for (var i = 0; i < excelRows.length; i++) {
        //Add the data row.
        var row = table.insertRow(-1);

        //Add the data cells.
        var cell = row.insertCell(-1);
        cell.innerHTML = excelRows[i].Id;

        cell = row.insertCell(-1);
        cell.innerHTML = excelRows[i].Name;

        cell = row.insertCell(-1);
        cell.innerHTML = excelRows[i].Country;
      }

      var dvExcel = document.getElementById("dvExcel");
      dvExcel.innerHTML = "";
      dvExcel.appendChild(table);
    },
    startloading(process) {
      if (process === "start") {
        // console.log("start");
        this.$vs.loading();
      } else {
        // console.log("end");
        this.$vs.loading.close();
      }
      // console.log("loading start");
    },
    Upload() {
      var fileUpload = document.getElementById("fileUpload");
      // console.log("loading start");
      let processedExcel = [];
      var regex = /^([a-zA-Z0-9\s_\\.\-:])+(.xls|.xlsx)$/;
      if (regex.test(fileUpload.value.toLowerCase())) {
        if (typeof FileReader != "undefined") {
          var reader = new FileReader();
          var self = this;
          // console.log(this);
          if (reader.readAsBinaryString) {
            reader.onload = function(e) {
              var workbook = XLSX.read(e.target.result, {
                type: "binary"
              });
              var firstSheet = workbook.SheetNames[0];
              var excelRows = XLSX.utils.sheet_to_row_object_array(
                workbook.Sheets[firstSheet],
                { raw: false }
              );
              let processedrows = [];
              excelRows.forEach(rows => {
                // console.log(rows);
                if (rows.city === undefined) {
                  rows.city = "";
                }
                if (rows.enquiry_date === undefined) {
                  rows.enquiry_date = "";
                }
                // if (rows.net_enquiry_no === undefined) {
                //   rows.net_enquiry_no = "";
                // }
                if (rows.name === undefined) {
                  rows.name = "";
                }
                if (rows.mobile === undefined) {
                  rows.mobile = "";
                }
                if (rows.email === undefined) {
                  rows.email = "";
                }
                if (rows.net_enquiry_type === undefined) {
                  rows.net_enquiry_type = "";
                }
                processedrows.push(rows);
              });
              processedExcel = processedrows;
              console.log("Processed Excel", processedExcel);
              self.uploadRowsToServer(processedExcel);
              // processedExcel.forEach(async row => {
              //   let responsne = await self.uploadRow(row);
              //   self.completedpercentage = self.completedpercentage +=
              //     100 / processedExcel.length;
              // });
            };
            // this.notuploaded = false;
            // console.log("load ended");
            reader.readAsBinaryString(fileUpload.files[0]);
          } else {
            reader.onload = function(e) {
              var data = "";
              var bytes = new Uint8Array(e.target.result);
              for (var i = 0; i < bytes.byteLength; i++) {
                data += String.fromCharCode(bytes[i]);
              }
              var workbook = XLSX.read(data, {
                type: "binary"
              });
              var firstSheet = workbook.SheetNames[0];
              var excelRows = XLSX.utils.sheet_to_row_object_array(
                workbook.Sheets[firstSheet],
                { date_format: "dd/mm/yyyy" }
              );
              console.log(excelRows);
            };
            reader.readAsArrayBuffer(fileUpload.files[0]);
          }
        } else {
          alert("This browser does not support HTML5.");
        }
      } else {
        alert("Please upload a valid Excel file.");
      }
    },
    async uploadRowsToServer(ProccessedRows) {
      for (let index = 0; index < ProccessedRows.length; index++) {
        const element = ProccessedRows[index];
        let response = await this.uploadRow(element);
        // console.log(response.data.status);
        if (response.data.status !== "success") {
          this.$vs.notify({
            time: 20000,
            title: response.data.status,
            text: response.data.message,
            color: "danger",
            position: "top-center",
            click: () => {}
          });
          break;
        }
        self.completedpercentage = this.completedpercentage +=
          100 / ProccessedRows.length;
      }
    },
    uploadRow(data) {
      // let url = `${constants.SERVER_API}addNetEnquiry`
      // console.log(data);
      let url = `${constants.SERVER_API}netEnquiriesFromMiles`;
      return new Promise(async (resolve, reject) => {
        axios
          .put(url, data, {
            headers: { Authorization: `Bearer ${this.api_token}` }
          })
          .then(response => {
            // console.log(response);
            setTimeout(function() {
              resolve(response);
            }, 300);
          })
          .catch(error => {
            reject(error);
            if (error.response) {
              console.log("error", error.response.status);
              if (error.response.status === 401) {
                this.ReDirect_to_login();
                // this.EmitEvent('close-popup-redirect')
              }
            }
          });
      });
    },
    timetoTimestamp(selected_date) {
      var timestamp = new Date(selected_date).setHours(0, 0, 0, 0) / 1000;
      return timestamp;
    },
    successUpload(e) {
      console.log("success upload", e);
      var reader = new FileReader();

      //For Browsers other than IE.
      if (reader.readAsBinaryString) {
        reader.onload = function(e) {
          console.log(e);
          // this.ProcessExcel(e.target.result);
        };
        // reader.readAsBinaryString(fileUpload.files[0]);
      }
      this.$vs.notify({
        color: "success",
        title: "Upload Success",
        text: "File successfully uploaded"
      });
    },
    errorUpload(e) {
      console.log("error", e);
    },
    changeUpload(e, files) {
      console.log("change", e);
      var reader = new FileReader();

      //For Browsers other than IE.
      if (reader.readAsBinaryString) {
        reader.onload = function(e) {
          console.log(e);
          // this.ProcessExcel(e.target.result);
        };
        // reader.readAsBinaryString(fileUpload.files[0]);
      }
      // this.process;
      // console.log("result", this.saveFile);
    }
  },
  beforeDestroy() {
    /* let navbar = document.getElementsByClassName("vx-navbar-wrapper");
    navbar[0].style.display = ""; */
  }
};
</script>

<style></style>
